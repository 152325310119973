// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconBrush = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3614 14.0647L11.3239 11.5147C11.1589 11.376 11.1439 11.1285 11.2901 10.971L18.7377 2.96848C19.0189 2.66473 19.3864 2.44723 19.7951 2.38348C20.8864 2.22223 21.8501 3.02849 21.8801 4.13099C21.8914 4.54349 21.7376 4.94474 21.4901 5.27474L14.9052 13.9972C14.7739 14.1735 14.5264 14.1997 14.3614 14.0647ZM10.5439 12.3247C10.3264 12.1447 10.0564 12.036 9.77512 12.0285C9.18262 12.0097 8.16268 12.1485 7.39768 13.1385C6.03268 14.9047 6.6664 16.581 5.3014 17.541C4.16515 18.3397 2.99141 17.796 2.41016 17.4172C2.26766 17.3235 2.08391 17.451 2.12516 17.616C2.24141 18.0922 2.49262 18.8497 3.02137 19.5285C4.33387 21.216 7.85888 22.7272 11.0951 20.571C13.2551 19.1347 13.8064 17.0122 13.9452 15.921C13.9977 15.4972 13.8289 15.0772 13.4989 14.8035L10.5439 12.3247Z" />
    </svg>
  );
};
