// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconDojoPlus = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.6006 14.079C6.0931 14.352 7.63814 14.751 8.44745 15.6015C9.25676 16.452 9.6036 18.0165 9.90841 19.3815C10.0976 20.211 10.3288 20.9985 10.6547 21.5655C10.9805 22.1325 11.4114 22.5 11.9895 22.5C12.5676 22.5 12.9985 22.143 13.3243 21.576C13.6501 21.009 13.8814 20.2215 14.0706 19.3815C14.3859 17.943 14.7432 16.3785 15.5526 15.549C16.3619 14.7195 17.9174 14.352 19.3784 14.079C20.2087 13.9215 20.997 13.6905 21.5646 13.3545C22.1321 13.0185 22.5 12.5775 22.5 12C22.5 11.4225 22.1321 10.992 21.5646 10.656C20.997 10.32 20.2087 10.089 19.3784 9.921C17.9489 9.627 16.3724 9.102 15.542 8.2095C14.7117 7.317 14.3333 5.7315 14.0706 4.6185C13.8604 3.768 13.6396 2.991 13.3138 2.424C12.988 1.857 12.5781 1.5 12 1.5C11.4219 1.5 10.9805 1.857 10.6547 2.4345C10.3288 3.012 10.0871 3.789 9.92943 4.629C9.64565 6.099 9.26726 7.653 8.43693 8.472C7.6066 9.291 6.04054 9.6375 4.62162 9.9315C3.75976 10.11 2.98198 10.341 2.41442 10.6665C1.84685 10.992 1.5 11.4225 1.5 12C1.5 12.5775 1.85736 13.0185 2.42493 13.3545C2.99249 13.6905 3.77027 13.9215 4.63214 14.079H4.6006Z" />
    </svg>
  );
};
