// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconMegaphone = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.3639 6.81787C18.1802 6.87412 18.0752 7.06912 18.1314 7.25662L19.1552 10.6091C19.2114 10.7929 19.4064 10.8979 19.5939 10.8416C20.7039 10.5041 21.3302 9.32662 20.9927 8.21662C20.6552 7.10662 19.4777 6.48037 18.3677 6.81787H18.3639ZM18.2702 16.7591C17.7414 17.1491 17.0477 17.1079 16.5789 16.7141C14.8652 15.2666 12.6902 14.5504 10.5039 14.6554C10.2189 14.6704 10.0202 14.9404 10.1027 15.2141L11.3139 19.1929C11.5577 19.9916 11.0552 20.8354 10.2077 20.9854C9.5964 21.0941 8.9889 20.7454 8.70015 20.1941L6.47265 15.9454C6.39015 15.7841 6.2139 15.6866 6.0339 15.7166C4.70265 15.9566 3.3714 15.1466 3.0039 13.7966C2.62515 12.3979 3.51015 10.9654 4.89765 10.5416L6.6114 10.0204C9.4989 9.13912 11.9589 6.95287 12.6789 4.02412C12.8177 3.45787 13.3127 3.03412 13.9164 2.99662C14.4489 2.96662 14.9439 3.27787 15.2027 3.74662C17.2089 7.39162 18.4314 11.4191 18.7914 15.5666V15.6154C18.7914 16.0579 18.6152 16.5004 18.2627 16.7629L18.2702 16.7591Z" />
    </svg>
  );
};
