// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconArchive = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.0901 4.32372C21.9438 3.49122 21.2613 2.86122 20.4213 2.78247C17.62 2.51622 14.8076 2.38123 11.9951 2.38123C9.18255 2.38123 6.37005 2.51622 3.5688 2.78247C2.7288 2.86122 2.0463 3.49497 1.90005 4.32372C1.69005 5.63997 1.71255 6.97873 1.9638 8.28748L1.9863 8.39998C2.05005 8.75623 2.27505 9.04873 2.58255 9.20248C2.7288 9.27748 2.81505 9.42372 2.7963 9.58497C2.39505 12.9187 2.4213 16.29 2.8788 19.62C2.9388 20.0475 3.13755 20.4262 3.43005 20.7112C3.72255 20.9962 4.1088 21.1837 4.54005 21.2287C7.0188 21.4875 9.5088 21.6187 11.9988 21.6187C14.4888 21.6187 16.9788 21.4875 19.4576 21.2287C19.8851 21.1837 20.2713 20.9962 20.5675 20.7112C20.8638 20.4262 21.0626 20.0475 21.1226 19.62C21.5801 16.29 21.6063 12.9187 21.2051 9.58497C21.1863 9.42372 21.2763 9.27748 21.4188 9.20248C21.7226 9.04498 21.9513 8.75623 22.015 8.39998L22.0376 8.28748C22.2888 6.97873 22.3076 5.63997 22.1013 4.32372H22.0901ZM3.70005 4.94622C3.72255 4.76997 3.86505 4.63498 4.0413 4.61998C6.6738 4.37998 9.34755 4.25623 11.9913 4.25623C14.6351 4.25623 17.3088 4.37998 19.9413 4.61998C20.1176 4.63498 20.2601 4.77372 20.2826 4.94622C20.3726 5.66997 20.3801 6.40122 20.305 7.12497C20.2863 7.31622 20.1213 7.45872 19.93 7.45872H4.05255C3.8613 7.45872 3.6963 7.31622 3.67755 7.12497C3.60255 6.40122 3.61005 5.67372 3.70005 4.94622ZM19.2963 19.065C19.2738 19.2375 19.1313 19.3762 18.9588 19.395C16.6526 19.6275 14.3088 19.7437 11.9913 19.7437C9.6738 19.7437 7.3338 19.6275 5.0238 19.395C4.84755 19.3762 4.7088 19.2412 4.6863 19.065C4.2813 15.945 4.2738 12.7837 4.66005 9.65998C4.68255 9.47248 4.8438 9.32998 5.03505 9.32998H18.9438C19.1351 9.32998 19.2963 9.47248 19.3188 9.65998C19.7051 12.7837 19.6976 15.945 19.2926 19.065H19.2963ZM16.1163 12C16.1163 12.7237 15.5276 13.3125 14.8038 13.3125H9.1788C8.45505 13.3125 7.8663 12.7237 7.8663 12C7.8663 11.2762 8.45505 10.6875 9.1788 10.6875H14.8038C15.5276 10.6875 16.1163 11.2762 16.1163 12Z" />
    </svg>
  );
};
