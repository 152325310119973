// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPause = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.825 21.2663C8.91 21.4613 7.965 21.4613 7.05 21.2663L6.74625 21.2025C6.17625 21.0788 5.73 20.64 5.59875 20.07C4.38 14.76 4.38 9.24377 5.59875 3.93377C5.73 3.36377 6.17625 2.92502 6.74625 2.80127L7.05 2.73752C7.965 2.54252 8.91 2.54252 9.825 2.73752C10.5413 2.89127 11.04 3.53627 11.01 4.26752C10.7963 9.42377 10.7963 14.5838 11.01 19.74C11.04 20.4713 10.5413 21.1163 9.825 21.27V21.2663Z" />
      <path d="M14.175 2.73377C15.09 2.53877 16.035 2.53877 16.95 2.73377L17.2538 2.79752C17.8238 2.92127 18.27 3.36002 18.4013 3.93002C19.62 9.24002 19.62 14.7563 18.4013 20.0663C18.27 20.6363 17.8238 21.075 17.2538 21.1988L16.95 21.2625C16.035 21.4575 15.09 21.4575 14.175 21.2625C13.4588 21.1088 12.96 20.4638 12.99 19.7325C13.2038 14.5763 13.2038 9.41627 12.99 4.26002C12.96 3.52877 13.4588 2.88377 14.175 2.73002V2.73377Z" />
    </svg>
  );
};
