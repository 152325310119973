// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconLeader = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9989 14.6681C10.7576 14.6681 9.74887 13.6594 9.74887 12.4181C9.74887 11.1769 10.7576 10.1681 11.9989 10.1681C13.2401 10.1681 14.2489 11.1769 14.2489 12.4181C14.2489 13.6594 13.2401 14.6681 11.9989 14.6681ZM14.2489 16.8506C14.2489 16.3331 13.8289 15.9131 13.3114 15.9131H10.6864C10.1689 15.9131 9.74887 16.3331 9.74887 16.8506C9.74887 17.3681 10.1689 17.7881 10.6864 17.7881H13.3114C13.8289 17.7881 14.2489 17.3681 14.2489 16.8506ZM17.6876 19.4419C17.4476 20.6269 16.4801 21.5231 15.2801 21.6731C14.1926 21.8081 13.0976 21.8756 11.9989 21.8756C10.9001 21.8756 9.80887 21.8081 8.71762 21.6731C7.52512 21.5231 6.55387 20.6381 6.31387 19.4606C5.59387 15.9169 5.59012 12.3206 6.31387 8.77313C6.55387 7.58813 7.52137 6.69189 8.72137 6.54189C8.81887 6.53064 8.84137 6.53439 8.87887 6.47064C8.91637 6.40689 8.86762 6.35813 8.86762 6.35813C7.82887 5.28938 7.01512 4.02563 6.46387 2.64188C6.36637 2.39438 6.54262 2.12439 6.80887 2.12439H9.57262C9.72637 2.12439 9.86512 2.22188 9.92137 2.36438C10.3676 3.48563 10.9676 4.53938 11.7064 5.48813C11.8526 5.67938 12.1489 5.67938 12.2951 5.48813C13.0339 4.53563 13.6376 3.48563 14.0801 2.36063C14.1364 2.21813 14.2751 2.12439 14.4289 2.12439H17.1926C17.4589 2.12439 17.6389 2.39438 17.5376 2.64188C16.9901 4.02563 16.1726 5.28938 15.1339 6.35813C15.1339 6.35813 15.0926 6.41063 15.1226 6.47813C15.1489 6.54188 15.2351 6.53814 15.2801 6.54189C16.4801 6.69189 17.4476 7.58813 17.6876 8.77313C18.4076 12.3131 18.4076 15.9019 17.6876 19.4419ZM15.8501 9.14438C15.7714 8.75063 15.4489 8.45064 15.0476 8.40189C14.0351 8.27439 13.0189 8.21439 12.0026 8.21439C10.9864 8.21439 9.96637 8.27814 8.95762 8.40189C8.55637 8.45064 8.23387 8.75063 8.15512 9.14438C7.48762 12.4369 7.48762 15.7744 8.15512 19.0669C8.23387 19.4606 8.55637 19.7606 8.95762 19.8094C10.9789 20.0606 13.0301 20.0606 15.0514 19.8094C15.4526 19.7606 15.7751 19.4606 15.8539 19.0669C16.5214 15.7744 16.5214 12.4369 15.8539 9.14438H15.8501Z" />
    </svg>
  );
};
