// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconMerge = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8426 9.67495L17.5425 5.72995C17.19 5.3062 16.545 5.2762 16.1513 5.67745C15.81 6.0262 15.8251 6.5962 16.14 6.9712L17.8613 9.02995C17.9625 9.1537 17.8763 9.33745 17.7188 9.33745H4.20755C3.7163 9.33745 3.27005 9.68995 3.2213 10.1775C3.16505 10.74 3.6038 11.2125 4.15505 11.2125H10.1513C10.2975 11.2125 10.3913 11.3737 10.3125 11.4975C10.1475 11.7562 10.005 12.03 9.89255 12.3225L8.81255 14.9287C8.40005 16.0087 7.34255 16.7325 6.18755 16.7325H4.2113C3.72005 16.7325 3.2738 17.085 3.22505 17.5725C3.1688 18.135 3.60755 18.6075 4.1588 18.6075H6.18755C8.11505 18.6075 9.8738 17.4 10.5525 15.6225L11.6325 13.0162C12.0488 11.9362 13.1025 11.2125 14.2575 11.2125H17.7188C17.8763 11.2125 17.9663 11.4 17.8613 11.52L16.1438 13.5712C15.8026 13.98 15.8288 14.6325 16.2488 14.9587C16.4213 15.09 16.6238 15.1575 16.8225 15.1575C17.0888 15.1575 17.355 15.045 17.5425 14.82L20.8426 10.875C21.135 10.5262 21.135 10.02 20.8426 9.6712V9.67495Z" />
    </svg>
  );
};
