// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconDojosafe = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.56772 8.78005C8.62526 7.58175 10.4113 7.48689 11.5973 8.481C11.7237 8.59237 11.9063 8.59237 12.0387 8.481C13.2227 7.46832 14.9926 7.57969 16.0682 8.78005C17.1258 9.96185 17.0154 11.8923 15.9217 13.0164L12.6026 16.4277C12.1631 16.8773 11.4708 16.8773 11.0333 16.4277L7.7142 13.0164C6.63859 11.8923 6.52823 9.96185 7.56772 8.78005Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4091 2.39784C12.417 1.86374 11.2162 1.86623 10.2265 2.41248C9.77547 2.66143 9.25287 2.94146 8.76993 3.18012C8.2691 3.42763 7.86928 3.60109 7.64124 3.66777C7.0357 3.84485 5.62684 4.18135 4.54597 4.43332C3.41971 4.69587 2.45517 5.57027 2.2518 6.80915C1.93822 8.7194 1.61491 12.5026 3.30015 15.6342C4.91601 18.6369 8.09655 20.5225 9.9219 21.4168C11.1267 22.0071 12.518 21.9995 13.7166 21.4025C15.5336 20.4976 18.6986 18.603 20.3331 15.6431C22.0642 12.5081 21.7051 8.65675 21.3814 6.75516C21.1774 5.55685 20.2581 4.69698 19.1657 4.41813C18.1635 4.16228 16.8093 3.81864 16.1329 3.65605C15.8969 3.59934 15.4764 3.43084 14.9436 3.17956C14.4319 2.93823 13.8804 2.65157 13.4091 2.39784ZM11.1326 4.05403C11.5612 3.81745 12.086 3.81497 12.5203 4.0488C13.006 4.31024 13.5898 4.61417 14.1438 4.87542C14.6766 5.12671 15.2521 5.37276 15.6947 5.47913C16.3556 5.638 17.6958 5.97802 18.702 6.23487C19.1639 6.3528 19.4678 6.68666 19.533 7.0698C19.8442 8.89828 20.0956 12.1944 18.6917 14.7367C17.3476 17.1709 14.6297 18.8531 12.8807 19.7242C12.2023 20.062 11.4251 20.0653 10.7468 19.733C8.99301 18.8738 6.27445 17.2045 4.95126 14.7457C3.57581 12.1897 3.80199 8.94069 4.10204 7.11288C4.16681 6.71829 4.48581 6.37262 4.97165 6.25936C6.03314 6.0119 7.50061 5.66242 8.1675 5.46741C8.56927 5.34992 9.0999 5.10852 9.60064 4.86106C10.1193 4.60475 10.6693 4.3097 11.1326 4.05403Z"
      />
    </svg>
  );
};
