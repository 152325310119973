// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconAirplane = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.3612 2.52941L18.6599 2.80316C13.1324 4.95566 7.98744 7.98566 3.43119 11.7844C2.78619 12.3207 2.93619 13.3482 3.70869 13.6819L6.74994 14.9907C6.89619 15.0544 7.06869 15.0169 7.17744 14.8969L7.79619 14.2107C10.4624 11.2519 13.5149 8.66441 16.8749 6.52316C17.0624 6.40316 17.2649 6.65441 17.1112 6.81191C14.4712 9.53066 12.0599 12.4632 9.89619 15.5719C9.61119 15.9957 9.43494 16.4869 9.37494 16.9932C9.21369 18.4069 9.25494 19.8357 9.50244 21.2419C9.52494 21.3732 9.60744 21.4669 9.71244 21.5157C9.84369 21.5757 10.0087 21.5644 10.1287 21.4519C11.2462 20.4094 12.2774 19.2807 13.2187 18.0807C13.3237 17.9457 13.5037 17.9007 13.6612 17.9682L17.0099 19.4119C17.6474 19.6857 18.3824 19.3219 18.5474 18.6469L18.7162 17.9719C19.8787 13.2657 20.6324 8.46566 20.9737 3.62816C20.9737 2.79941 20.1412 2.22941 19.3687 2.52941H19.3612Z" />
    </svg>
  );
};
