// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconShuffle = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.25825 6.95973C3.307 6.47223 3.75325 6.11974 4.2445 6.11974H7.26702C8.69577 6.11974 10.0308 6.78724 10.9083 7.86724C11.002 7.97974 11.0095 8.14098 10.9457 8.27223C10.8745 8.41848 10.807 8.56849 10.747 8.72599L10.2895 9.91848C10.2295 10.0797 10.0008 10.0797 9.94077 9.91848L9.89578 9.80223C9.47953 8.71473 8.43328 7.99848 7.27078 7.99848H4.192C3.6445 7.99473 3.202 7.52223 3.25825 6.95973ZM16.1733 7.99474H17.602C17.7595 7.99474 17.8495 8.17848 17.7445 8.30223L16.9758 9.22474C16.6345 9.63349 16.6607 10.286 17.0807 10.6122C17.2532 10.7435 17.4558 10.811 17.6583 10.811C17.9283 10.811 18.1945 10.6947 18.3783 10.4735L20.4183 8.02474C20.8495 7.51099 20.8457 6.76099 20.4107 6.24349L18.3482 3.78724C18.0332 3.41224 17.4745 3.29598 17.0695 3.57348C16.6045 3.89223 16.522 4.53349 16.8783 4.95349L17.5982 5.81223C17.6995 5.93598 17.6132 6.11974 17.4557 6.11974H16.177C14.2495 6.11974 12.4908 7.32724 11.8008 9.12724L9.89953 14.081C9.48328 15.1685 8.43703 15.8847 7.27453 15.8847H4.25201C3.76076 15.8847 3.31451 16.2372 3.26576 16.7247C3.20951 17.2872 3.64825 17.7597 4.1995 17.7597H7.27828C9.20578 17.7597 10.9645 16.5522 11.6545 14.7522L13.5558 9.79849C13.9645 8.71099 15.0108 7.99474 16.1733 7.99474ZM18.3108 13.5147C17.9583 13.0947 17.3095 13.061 16.9195 13.466C16.5783 13.8185 16.597 14.3847 16.912 14.7597L17.5982 15.5772C17.6995 15.701 17.6132 15.8847 17.4557 15.8847H16.177C15.0145 15.8847 13.9683 15.1685 13.552 14.081L13.507 13.9647C13.447 13.8035 13.2183 13.8035 13.1583 13.9647L12.7008 15.1572C12.6408 15.311 12.5733 15.4647 12.502 15.611C12.4383 15.7422 12.4457 15.9035 12.5395 16.016C13.417 17.096 14.752 17.7635 16.1808 17.7635H17.6095C17.767 17.7635 17.857 17.9472 17.752 18.071L16.9833 18.9935C16.642 19.4022 16.6683 20.0547 17.0883 20.381C17.2608 20.5122 17.4633 20.5797 17.6658 20.5797C17.9358 20.5797 18.202 20.4635 18.3858 20.2422L20.4258 17.7935C20.857 17.2797 20.8533 16.5297 20.4183 16.0122L18.3108 13.5147Z" />
    </svg>
  );
};
