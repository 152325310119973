// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconCameraFilled = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4951 11.5055C12.6189 11.9967 13.0164 12.3905 13.5039 12.5142C14.0439 12.6492 14.5426 12.4767 14.8726 12.1355C14.9739 12.0305 15.1539 12.0905 15.1876 12.2292C15.3151 12.728 15.3264 13.2717 15.1801 13.8342C14.8389 15.1692 13.6576 16.1705 12.2851 16.2867C10.1551 16.4667 8.40388 14.6105 8.76013 12.4505C8.95513 11.273 9.80636 10.2792 10.9389 9.90048C11.5876 9.68298 12.2139 9.67923 12.7801 9.82173C12.9189 9.85548 12.9789 10.0355 12.8739 10.1367C12.5326 10.4705 12.3601 10.9692 12.4951 11.5055Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7601 7.66926C21.3263 8.20176 21.7313 8.91051 21.8813 9.71675C22.2901 11.8955 22.2901 14.1305 21.8813 16.3018C21.7426 17.0443 21.3863 17.7043 20.8876 18.2218C20.3026 18.8255 19.5188 19.2305 18.6413 19.3355C14.2276 19.8643 9.76882 19.8643 5.35507 19.3355C4.50382 19.2343 3.73883 18.848 3.16133 18.2743C2.63633 17.753 2.26132 17.0743 2.11882 16.3055C1.71007 14.1268 1.71007 11.8918 2.11882 9.71301C2.28757 8.82051 2.76382 8.048 3.43507 7.5005C3.97132 7.06175 4.63507 6.76926 5.35882 6.68301C5.87632 6.62301 6.39383 6.5705 6.91133 6.52175C7.04633 6.5105 7.1626 6.42425 7.21885 6.3005L7.45135 5.76801C7.57135 5.49426 7.75136 5.258 7.97261 5.07425C8.17886 4.9055 8.42257 4.778 8.68507 4.70675C9.77257 4.41425 10.8901 4.26801 12.0039 4.26801C13.1214 4.26801 14.2351 4.41425 15.3226 4.70675C15.5963 4.78175 15.8476 4.91676 16.0576 5.09676C16.2676 5.28051 16.4401 5.50551 16.5526 5.76801L16.7851 6.3005C16.8376 6.42425 16.9576 6.5105 17.0926 6.52175C17.6101 6.56675 18.1276 6.61926 18.6451 6.68301C19.4588 6.78051 20.1938 7.13676 20.7601 7.66926ZM7.5001 13.013C7.5001 15.4993 9.51385 17.513 12.0001 17.513C14.4864 17.513 16.5001 15.4993 16.5001 13.013C16.5001 10.5268 14.4864 8.51301 12.0001 8.51301C9.51385 8.51301 7.5001 10.5268 7.5001 13.013ZM17.6251 9.63801C17.6251 10.2605 18.1276 10.763 18.7501 10.763C19.3726 10.763 19.8751 10.2605 19.8751 9.63801C19.8751 9.01551 19.3726 8.51301 18.7501 8.51301C18.1276 8.51301 17.6251 9.01551 17.6251 9.63801Z"
      />
    </svg>
  );
};
