// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconFolder = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.68434 7.38389C8.41809 7.92764 7.87434 8.28014 7.27059 8.29889V8.29514L3.17184 8.43389C2.94309 8.44139 2.75934 8.25014 2.78184 8.02514C2.86434 7.13264 2.98059 6.24014 3.13059 5.35139C3.18309 5.02889 3.44559 4.78139 3.77184 4.74014L7.27059 4.30514C7.52934 4.27139 7.78809 4.38014 7.94934 4.58639L8.93934 5.85389C9.13434 6.06764 9.17934 6.37889 9.05184 6.63764L8.98434 6.77264L8.68434 7.38389Z" />
      <path d="M20.8606 7.2639V7.2864L20.8681 7.28265C21.5394 10.7026 21.5394 15.1614 20.8681 18.5814C20.8081 18.8964 20.5456 19.1364 20.2269 19.1776H20.2156C14.7594 19.8751 9.23561 19.8751 3.77936 19.1776C3.44936 19.1364 3.19061 18.8814 3.13436 18.5589C2.70686 16.0126 2.55311 12.4951 2.66186 9.92265C2.66936 9.72764 2.83061 9.57015 3.02561 9.56264L7.30812 9.42015C8.33186 9.3864 9.24686 8.7939 9.69686 7.87515L10.4544 6.32265C10.5144 6.1989 10.6381 6.1164 10.7769 6.1089C13.9456 5.96265 17.1219 6.15015 20.2531 6.6714C20.5569 6.7239 20.7969 6.96015 20.8606 7.2639Z" />
    </svg>
  );
};
