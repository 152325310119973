// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPlay = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.078 10.8168C15.9827 7.46287 11.3299 4.9633 6.36497 3.45237C5.30607 3.13153 4.21563 3.84038 4.00876 4.98943C3.16375 9.6192 3.16375 14.3795 4.00876 19.0093C4.21914 20.1584 5.30958 20.8709 6.36497 20.5463C11.3334 19.0317 15.9862 16.5321 20.078 13.182C20.8073 12.5851 20.8073 11.4137 20.078 10.8168Z" />
    </svg>
  );
};
