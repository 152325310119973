// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconAlertOnFilled = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.4387 14.0718L19.6612 15.8156C19.8 16.0143 19.875 16.2543 19.875 16.4981C19.875 16.7043 19.7063 16.8731 19.5 16.8731H4.5C4.29375 16.8731 4.125 16.7043 4.125 16.4981C4.125 16.2543 4.2 16.0143 4.33875 15.8156L5.56125 14.0718C6.33375 12.9656 6.75 11.6493 6.75 10.3031V8.62683C6.75 5.72808 9.10125 3.37683 12 3.37683C14.8988 3.37683 17.25 5.72808 17.25 8.62683V10.3031C17.25 11.6531 17.6662 12.9693 18.4387 14.0718ZM14.25 18.3731C14.25 18.1668 14.0813 17.9981 13.875 17.9981H10.125C9.91875 17.9981 9.75 18.1668 9.75 18.3731C9.75 19.6143 10.7587 20.6231 12 20.6231C13.2413 20.6231 14.25 19.6143 14.25 18.3731Z" />
    </svg>
  );
};
