// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconInbox = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.567 9.83018C20.7233 8.42018 19.482 6.54518 17.9633 5.76143C17.697 5.58893 17.3895 5.48393 17.0708 5.45018C13.7108 5.09018 10.302 5.09018 6.94202 5.45018C6.62702 5.48393 6.31953 5.59268 6.04953 5.76143C4.53078 6.54518 3.30077 8.42768 2.44577 9.83018C2.17577 10.2727 1.99952 10.9177 1.89452 11.6302C1.66577 13.1789 1.67327 14.7502 1.88327 16.3064C2.03327 17.4239 2.92202 18.2864 4.04702 18.4064C9.33077 18.9577 14.6858 18.9577 19.9695 18.4064C21.0795 18.3127 21.9758 17.4652 22.1258 16.3552C22.3358 14.7839 22.317 13.2052 22.1183 11.6264C22.0283 10.9139 21.927 10.4227 21.5708 9.82643L21.567 9.83018ZM6.91577 7.42643C6.99452 7.39643 7.05827 7.32518 7.14452 7.31393C10.3733 6.96893 13.647 6.96893 16.872 7.31393C17.8133 7.71518 18.5483 8.69393 19.2833 9.75518H16.7445C16.4258 9.75518 16.1295 9.91643 15.957 10.1864L14.8358 11.9302C12.957 12.1102 11.0595 12.1102 9.18078 11.9302L8.05953 10.1864C7.88703 9.92018 7.59077 9.75518 7.27202 9.75518H4.73703C5.38953 8.79518 6.14703 7.82393 6.91953 7.42643H6.91577ZM20.2658 16.1039C20.232 16.3514 20.022 16.5314 19.7745 16.5389C14.622 17.0752 9.39453 17.0752 4.24203 16.5389C3.98328 16.5127 3.77702 16.3139 3.74327 16.0552C3.54452 14.5814 3.56327 13.0927 3.79202 11.6264H6.75452L7.84203 13.3177C7.99578 13.5539 8.24703 13.7114 8.52828 13.7414C10.8345 13.9964 13.1745 13.9964 15.4808 13.7414C15.762 13.7114 16.0133 13.5539 16.167 13.3177L17.2545 11.6264H20.2208C20.4495 13.1077 20.4645 14.6114 20.2658 16.1039Z" />
    </svg>
  );
};
