// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconBook = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.7043 4.6218C18.6855 4.3368 18.6031 4.07429 18.4718 3.83804C18.3893 3.68804 18.2918 3.54554 18.1755 3.42179C18.1005 3.34304 18.0142 3.27179 17.928 3.20804C17.6617 3.01679 17.3392 2.89304 16.9942 2.86304C15.333 2.72054 13.668 2.64929 11.9993 2.64929C10.3343 2.64929 8.66552 2.72054 7.00427 2.86304C6.65552 2.89304 6.33678 3.01679 6.07053 3.20804C5.62428 3.52679 5.328 4.03679 5.29425 4.61804C5.25675 5.23304 5.22675 5.84804 5.19675 6.46304C5.03175 10.153 5.03175 13.8468 5.19675 17.5368C5.223 18.1518 5.25675 18.7668 5.29425 19.3818C5.3055 19.573 5.35053 19.7568 5.41803 19.9293C5.48928 20.1168 5.58679 20.2855 5.71054 20.4393C5.81554 20.5705 5.93553 20.6943 6.07428 20.7918C6.34053 20.983 6.66303 21.1068 7.00803 21.1368C8.66928 21.2793 10.3343 21.3505 12.0031 21.3505C13.6681 21.3505 15.3367 21.2793 16.998 21.1368C17.3467 21.1068 17.6655 20.983 17.9317 20.7918C18.0217 20.728 18.1043 20.6568 18.1793 20.578C18.483 20.2668 18.6818 19.8468 18.708 19.3818C19.0005 14.4693 19.0005 9.5418 18.7043 4.6218ZM11.8118 4.91053V12.613C11.8118 12.7855 11.6018 12.868 11.4855 12.7368L9.95548 11.038C9.65923 10.708 9.13799 10.708 8.84174 11.038L7.33048 12.7143C7.21423 12.8455 6.99677 12.7555 7.00427 12.5793L7.32297 5.05303C7.33047 4.86178 7.48053 4.70802 7.67178 4.69302C8.92053 4.59927 10.1843 4.54303 11.4368 4.53178C11.643 4.53178 11.8118 4.70428 11.8118 4.91053Z" />
    </svg>
  );
};
