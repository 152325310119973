// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconHeartFilled = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5749 5.09412C9.35864 3.28662 6.02111 3.45911 4.04486 5.63786C2.10236 7.78661 2.30861 11.2966 4.31861 13.3404L10.5211 19.5429C11.3386 20.3604 12.6324 20.3604 13.4536 19.5429L19.6561 13.3404C21.6999 11.2966 21.9061 7.78661 19.9299 5.63786C17.9199 3.45536 14.6124 3.25287 12.3999 5.09412C12.1524 5.29662 11.8111 5.29662 11.5749 5.09412Z" />
    </svg>
  );
};
