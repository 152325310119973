// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconChart = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.1249 4.70248C11.3061 4.29373 9.33743 4.48873 7.52993 5.40373C3.74243 7.32373 1.9837 11.8912 3.5212 15.8512C5.27995 20.3775 10.4549 22.425 14.8011 20.4225C18.8699 18.5475 20.7074 13.8112 19.0387 9.70497C18.9599 9.50997 18.7275 9.42372 18.5362 9.51372L11.6737 12.6787C11.5274 12.7462 11.3736 12.6112 11.4149 12.4575L13.4024 5.16748C13.4549 4.96498 13.3311 4.75123 13.1249 4.70248Z" />
      <path d="M15.7462 2.39994L13.7587 9.68994C13.7174 9.84369 13.875 9.97493 14.0175 9.91118L20.88 6.74618C21.0713 6.65618 21.1575 6.42369 21.06 6.23619C20.0137 4.21494 18.2399 2.79369 16.2149 2.13744C16.0162 2.07369 15.8024 2.19744 15.7462 2.39994Z" />
    </svg>
  );
};
