// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconEye = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.3843 8.8425C20.1693 7.51125 18.9805 6.4875 17.7055 5.73001C14.2218 3.46125 9.77428 3.46125 6.29428 5.73001C5.01928 6.4875 3.83053 7.51125 2.61553 8.8425C1.07803 10.455 1.07803 13.545 2.61553 15.1575C3.83053 16.4888 5.01928 17.5125 6.29428 18.27C9.77428 20.5388 14.2218 20.5388 17.7055 18.27C18.9805 17.5125 20.1693 16.4888 21.3843 15.1575C22.9218 13.545 22.9218 10.455 21.3843 8.8425ZM11.998 17.7038C8.84803 17.7038 6.29428 15.15 6.29428 12C6.29428 8.85001 8.84803 6.29625 11.998 6.29625C15.148 6.29625 17.7018 8.85001 17.7018 12C17.7018 15.15 15.148 17.7038 11.998 17.7038ZM15.6393 10.89C15.7668 10.7588 15.9955 10.83 16.0405 11.01C16.198 11.64 16.213 12.3263 16.0293 13.0425C15.5943 14.7338 14.098 16.005 12.358 16.1513C9.65803 16.3763 7.43803 14.025 7.89178 11.2875C8.13928 9.795 9.21928 8.53501 10.6555 8.05501C11.4805 7.78126 12.2718 7.77375 12.9918 7.95375C13.168 7.99875 13.243 8.2275 13.1118 8.355C12.6805 8.775 12.463 9.40876 12.6318 10.0913C12.7855 10.71 13.288 11.2125 13.9068 11.3663C14.5893 11.5388 15.2193 11.3175 15.643 10.8863L15.6393 10.89Z" />
    </svg>
  );
};
