// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconChevronLeftSmall = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7169 17.116C14.9306 17.3607 15.0291 17.6841 14.9925 18.0048C14.956 18.3254 14.7816 18.6179 14.5144 18.8063C14.326 18.9413 14.1038 19.006 13.8788 19.006C13.5413 19.006 13.1981 18.8598 12.9563 18.5841L8.51815 13.4963C7.77565 12.647 7.77565 11.3645 8.51815 10.5151L12.99 5.38789C13.2066 5.13758 13.5216 4.99414 13.8535 4.99414C14.1094 4.99414 14.3625 5.08133 14.565 5.24164C14.7985 5.42727 14.9447 5.70289 14.9728 6.01508C15.0038 6.35258 14.8941 6.69008 14.6747 6.9432L10.3519 11.8988C10.2985 11.9607 10.2985 12.0535 10.3519 12.1126L14.7169 17.116Z" />
    </svg>
  );
};
