// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconPrinter = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.4475 5.05875C16.4475 5.28375 16.2487 5.46 16.0275 5.43C13.32 5.0925 10.5787 5.0925 7.87123 5.43C7.64623 5.45625 7.45123 5.28375 7.45123 5.05875C7.45123 4.01625 8.22747 3.135 9.26247 3.0075C11.0475 2.7825 12.855 2.7825 14.64 3.0075C15.6712 3.135 16.4475 4.01625 16.4475 5.05875ZM21.6375 13.8037C21.2175 15.24 19.9875 16.29 18.5025 16.4737L18.3975 16.485C18.0525 16.5262 17.7075 16.5675 17.3625 16.6012C17.1937 17.5762 16.7625 18.4987 16.1025 19.2637C15.0562 20.4675 13.5412 21.1612 11.9475 21.1612C10.3537 21.1612 8.8387 20.4712 7.79245 19.2637C7.1287 18.4987 6.7012 17.5762 6.53245 16.6012C6.18745 16.5675 5.84245 16.53 5.49745 16.485C3.95995 16.2937 2.69997 15.1762 2.32497 13.6725L2.28372 13.515C1.97997 12.3 1.97997 11.0325 2.28372 9.8175L2.32497 9.66C2.69997 8.15625 3.95995 7.03875 5.49745 6.8475C9.77995 6.31125 14.115 6.31125 18.3975 6.8475L18.5025 6.85875C19.9875 7.0425 21.2175 8.09249 21.6375 9.52875C22.0462 10.9275 22.0462 12.4087 21.6375 13.8037ZM15.5362 15.15L15.3412 13.785C15.3149 13.5975 15.1537 13.4625 14.97 13.4625C14.9587 13.4625 14.9475 13.4625 14.94 13.4625C13.9462 13.545 12.945 13.5862 11.9475 13.5862C10.95 13.5862 9.95249 13.545 8.95499 13.4625C8.94374 13.4625 8.93246 13.4625 8.92496 13.4625C8.74121 13.4625 8.57996 13.5975 8.55371 13.785L8.3587 15.15C8.04745 17.3325 9.74248 19.2862 11.9475 19.2862C14.1525 19.2862 15.8475 17.3325 15.5362 15.15ZM20.175 9.44624C20.175 8.92499 19.7512 8.505 19.2337 8.505C18.7125 8.505 18.2924 8.92874 18.2924 9.44624C18.2924 9.96749 18.7162 10.3875 19.2337 10.3875C19.755 10.3875 20.175 9.96749 20.175 9.44624Z" />
    </svg>
  );
};
