// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconGrid = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z" />
      <path d="M12 7.875C12.8284 7.875 13.5 7.20343 13.5 6.375C13.5 5.54657 12.8284 4.875 12 4.875C11.1716 4.875 10.5 5.54657 10.5 6.375C10.5 7.20343 11.1716 7.875 12 7.875Z" />
      <path d="M12 19.125C12.8284 19.125 13.5 18.4534 13.5 17.625C13.5 16.7966 12.8284 16.125 12 16.125C11.1716 16.125 10.5 16.7966 10.5 17.625C10.5 18.4534 11.1716 19.125 12 19.125Z" />
      <path d="M6.375 13.5C7.20343 13.5 7.875 12.8284 7.875 12C7.875 11.1716 7.20343 10.5 6.375 10.5C5.54657 10.5 4.875 11.1716 4.875 12C4.875 12.8284 5.54657 13.5 6.375 13.5Z" />
      <path d="M6.5625 8.0625C7.39093 8.0625 8.0625 7.39093 8.0625 6.5625C8.0625 5.73407 7.39093 5.0625 6.5625 5.0625C5.73407 5.0625 5.0625 5.73407 5.0625 6.5625C5.0625 7.39093 5.73407 8.0625 6.5625 8.0625Z" />
      <path d="M6.5625 18.9375C7.39093 18.9375 8.0625 18.2659 8.0625 17.4375C8.0625 16.6091 7.39093 15.9375 6.5625 15.9375C5.73407 15.9375 5.0625 16.6091 5.0625 17.4375C5.0625 18.2659 5.73407 18.9375 6.5625 18.9375Z" />
      <path d="M17.625 13.5C18.4534 13.5 19.125 12.8284 19.125 12C19.125 11.1716 18.4534 10.5 17.625 10.5C16.7966 10.5 16.125 11.1716 16.125 12C16.125 12.8284 16.7966 13.5 17.625 13.5Z" />
      <path d="M17.4375 8.0625C18.2659 8.0625 18.9375 7.39093 18.9375 6.5625C18.9375 5.73407 18.2659 5.0625 17.4375 5.0625C16.6091 5.0625 15.9375 5.73407 15.9375 6.5625C15.9375 7.39093 16.6091 8.0625 17.4375 8.0625Z" />
      <path d="M17.4375 18.9375C18.2659 18.9375 18.9375 18.2659 18.9375 17.4375C18.9375 16.6091 18.2659 15.9375 17.4375 15.9375C16.6091 15.9375 15.9375 16.6091 15.9375 17.4375C15.9375 18.2659 16.6091 18.9375 17.4375 18.9375Z" />
    </svg>
  );
};
