// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using scripts/lib/template.ts
import * as React from "react";
export const IconMessageMass = ({
  size,
  onClick,
  ...otherProps
}: React.SVGProps<SVGSVGElement> & {
  /** @deprecated - wrap in a `<DDSButton variant="ghost">` put the onClick on there */ onClick?: never;
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl";
}) => {
  const props = {
    ...otherProps,
    className: "ddsIcon " + (size || "") + " " + (otherProps.className || ""),
  };
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.109 12.0543C18.109 7.60487 14.5042 4 10.0547 4C5.6168 4 1.95436 7.70467 2.00043 12.1426C2.01963 14.0045 2.67613 15.7168 3.7549 17.0681C3.83168 17.1641 3.85853 17.2869 3.82782 17.4021L3.81245 17.4597C3.57059 18.3235 3.03698 19.0721 2.29604 19.5827C2.00428 19.7823 2.11943 20.2392 2.46878 20.2814C5.50547 20.6346 8.5767 20.5002 11.5865 19.9589L11.5827 19.9551C15.3027 19.241 18.109 15.9778 18.109 12.0543Z" />
      <path d="M17.312 5.05233C16.8156 4.80691 16.4916 5.48271 16.8805 5.87694C18.5289 7.54777 19.5316 9.75719 19.5316 12.1804C19.5316 15.5883 17.5462 18.571 14.568 20.227C14.4245 20.3067 14.5003 20.5531 14.6623 20.5267C14.7545 20.5117 14.8467 20.4963 14.9388 20.4806L14.9396 20.4782L14.9403 20.4757C18.9645 19.7427 22 16.3981 22 12.3769C22 9.19235 20.0985 6.42984 17.312 5.05233Z" />
    </svg>
  );
};
